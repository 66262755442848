/* You can add global styles to this file, and also import other style files */
.menu-link {
  text-decoration: none !important;
  cursor: pointer !important;
}
a {
  cursor: pointer !important;
}
.sub-category-active {
  color: #fff !important;
}
.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  border-color: #696cff !important;
  background-color: #696cff !important;
  color: #fff !important;
}
.card-header{
  padding: 0.8rem 1rem !important;
}
html {
  --scrollbarBG: #CFD8DC;
  --thumbBG: #90A4AE;
}
.link-info {
  color: #03c3ec !important;
}
.link-primary {
  color: #696cff !important;
}
.modal-size-50 {
  max-width: 50% !important;
  width: 50% !important;
}
.modal-size-60 {
  max-width: 60% !important;
  width: 60% !important;
}
.modal-size-70 {
  max-width: 70% !important;
  width: 70% !important;
}
.modal-size-80 {
  max-width: 80% !important;
  width: 80% !important;
}
.modal-size-90 {
  max-width: 90% !important;
  width: 90% !important;
}
.modal-size-100 {
  max-width: 100% !important;
  width: 100% !important;
}
.btn {
  border-radius: 4px !important;
  padding: 8px 10px !important;
}
.hidden {
  display: none !important;
 }
 .table-wrapper {
  width: 100% !important;
  max-height: 450px;
  overflow-x: auto !important;
  /* display:inline-block; */
}
.table-responsive .dropdown,
.table-responsive .btn-group,
.table-responsive .btn-group-vertical {
    position: static;
}

.td-text-small {
  font-size: 13px !important;
}

/** only for the body of the table. */
table.table tbody td {
  padding: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  /* padding-left:10px !important; */
}
table.table tbody td .remove-spacing {
  padding:0 !important;
}
.bg-accent-gray{
  --bs-table-bg: #fcfdfd;
  --bs-table-striped-bg: #f6f8f9;
  --bs-table-striped-color: #435971;
  --bs-table-active-bg: #eaedef;
  --bs-table-active-color: #435971;
  --bs-table-hover-bg: #f1f3f5;
  --bs-table-hover-color: #435971;
}

.image-row-8 {
  width: 100% !important;
  height: 87px !important;
  border-radius: 2px !important;
}

.image-row-5 {
  width: 50% !important;
  height: 87px !important;
  border-radius: 2px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
